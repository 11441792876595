<template>
  <div v-if="init" class="price-lists-wrapper">
    <div class="price-lists-syncs">
      <div class="sync price-list-sync">
        <div class="sync-info">
          <h2>Add/update prices (OLD)</h2>
          <p class="desc">Runs every minute</p>
        </div>

        <div class="system-log price-list-sync-log">
          <div
            v-for="entry in priceListSyncLog"
            :key="entry.id"
            class="single-log-entry"
          >
            <div class="log-entry-top">
              <span class="completed">{{
                entry.completedAt.toDate() | moment("from", "now")
              }}</span>
              <span class="ran-by">{{ entry.ranBy }}</span>
            </div>

            <div class="log-entry-data">
              <h6 class="updated">
                Updated prices: <span>{{ entry.updated.length }}</span>
              </h6>
              <p
                v-for="(info, index) in entry.updated"
                :key="entry.id + '-updated-' + index"
                class="log-entry-line"
              >
                <span
                  ><span v-if="info.type !== 'standard'">{{ info.type }}</span>
                  {{ info.id }}, product {{ info.productId }}, price
                  {{ info.price }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="sync price-list-sync">
        <div class="sync-info">
          <h2>Remove prices (OLD)</h2>
          <p class="desc">Runs every 15 minutes</p>
          <button
            v-if="userRole.slug === 'superAdmin'"
            class="run-button sync-products"
            :class="{ loading: removeLoader }"
            @click="deletePriceListEntries"
          >
            <span v-if="!removeLoader">Run manually</span>
            <span v-else>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="381.496px"
                height="381.496px"
                viewBox="0 0 381.496 381.496"
                style="enable-background: new 0 0 381.496 381.496"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <g id="Layer_5_13_">
                      <g>
                        <path
                          d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                          c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                          c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                          s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                          c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                          c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                          c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                          c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                          c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                          c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                          c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                          c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                          c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                          M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                          c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </button>
        </div>

        <div class="system-log price-list-sync-log">
          <div
            v-for="entry in priceListRemoveLog"
            :key="entry.id"
            class="single-log-entry"
          >
            <div class="log-entry-top">
              <span class="completed">{{
                entry.completedAt.toDate() | moment("from", "now")
              }}</span>
              <span class="ran-by">{{ entry.ranBy }}</span>
            </div>

            <div class="log-entry-data">
              <h6 class="removed">
                Removed prices: <span>{{ entry.removed.length }}</span>
              </h6>
              <p
                v-for="(info, index) in entry.removed"
                :key="entry.id + '-deleted-' + index"
                class="log-entry-line"
              >
                <span
                  ><span v-if="info.type !== 'standard'">{{ info.type }}</span>
                  {{ info.id }}, product {{ info.productId }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="sync price-list-sync">
        <div class="sync-info">
          <h2>Price sync (NEW)</h2>
          <p class="desc">Runs every 15 minutes. (No log).</p>
          <button
            v-if="userRole.slug === 'superAdmin'"
            class="run-button sync-products"
            :class="{ loading: syncLoader }"
            @click="syncPricesManually"
          >
            <span v-if="!syncLoader">Run manually</span>
            <span v-else>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="381.496px"
                height="381.496px"
                viewBox="0 0 381.496 381.496"
                style="enable-background: new 0 0 381.496 381.496"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <g id="Layer_5_13_">
                      <g>
                        <path
                          d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                          c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                          c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                          s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                          c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                          c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                          c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                          c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                          c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                          c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                          c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                          c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                          c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                          M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                          c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </button>
        </div>

        <div class="system-log price-list-sync-log">
          <div
            v-for="entry in priceListNewSyncLog"
            :key="entry.id"
            class="single-log-entry"
          >
            <div class="log-entry-top">
              <span class="completed">{{
                entry.completedAt.toDate() | moment("from", "now")
              }}</span>
              <span class="ran-by">{{ entry.ranBy }}</span>
            </div>

            <div class="log-entry-data">
              <h6 class="added">
                Added prices: <span>{{ entry.added }}</span>
              </h6>
              <h6 class="updated">
                Updated prices: <span>{{ entry.updated }}</span>
              </h6>
              <h6 class="removed">
                Removed prices: <span>{{ entry.removed }}</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="price-lists-viewer">
      <div class="price-lists-selector">
        <div class="price-lists-selection-inner">
          <h4>Price lists</h4>

          <div class="price-lists-list">
            <div
              v-for="company in companies"
              :key="company.vismaId"
              class="shortlist-item"
              :class="{
                active:
                  selectedPriceList !== null &&
                  selectedPriceList.id === company.vismaId.toString(),
              }"
              @click="selectPriceList(company.vismaId)"
            >
              <span class="name">{{ company.vismaId }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedPriceList != null" class="single-price-list">
        <h4>Selected: {{ selectedPriceList.id }}</h4>
        <div class="selected-price-list-products">
          <div
            v-for="(value, key) in selectedPriceList.products"
            :key="selectedPriceList.id + '-' + key"
          >
            <span v-if="getProductByVismaId(key) !== undefined"
              >{{ getProductByVismaId(key).name }} - {{ key }}</span
            >
            <span v-else>{{ key }}</span>
            <span class="price">{{
              (value.price * (1 - value.discount / 100)).toFixed(2)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "PriceLists",
  data() {
    return {
      removeLoader: false,
      syncLoader: false,
    };
  },
  computed: {
    ...mapState({
      productList: (state) => state.products.productList,
      companyList: (state) => state.settings.companyList.companies,
      appBrand: (state) => state.settings.appSettings.app,
      appEnv: (state) => state.settings.appSettings.env,
      appSettings: (state) => state.settings.appSettings,
      init: (state) => state.settings.init,
      priceGroups: (state) => state.settings.taxonomies.priceGroups1,
      userName: (state) => state.settings.user.name,
      priceListSyncLog: (state) => state.logs.priceListSyncLog,
      priceListNewSyncLog: (state) => state.logs.priceListNewSyncLog,
      priceListRemoveLog: (state) => state.logs.priceListRemoveLog,
      priceLists: (state) => state.priceLists.priceLists,
      selectedPriceList: (state) => state.priceLists.selectedPriceList,
      companies: (state) => state.companies.companies,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId", "userRole"]),
    ...mapGetters("products", ["getProductByVismaId"]),
  },
  mounted() {},
  async created() {},
  methods: {
    selectPriceList: function (id) {
      console.log(id);
      this.$store
        .dispatch("priceLists/bindSelectedPriceList", id.toString())
        .then(() => {
          console.log(this.selectedPriceList);
        });
    },
    deletePriceListEntries: function () {
      if (this.removeLoader) {
        return false;
      }

      this.removeLoader = true;

      this.axios
        .post(
          this.appSettings.cloudFunctions +
            "adminDeletePriceListEntriesManually",
          {
            env: this.$cookies.get("version"),
            brand: this.appBrand,
            user: this.userName,
          }
        )
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          this.removeLoader = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          this.removeLoader = false;
        });
    },
    syncPricesManually: function () {
      if (this.syncLoader) {
        return false;
      }

      this.syncLoader = true;

      this.axios
        .post(this.appSettings.cloudFunctions + "adminSyncPriceListsManually", {
          env: this.$cookies.get("version"),
          brand: this.appBrand,
          user: this.userName,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          this.syncLoader = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          this.syncLoader = false;
        });
    },
  },
};
</script>
